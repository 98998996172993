'use client';

import { actionsInputInicio } from '&/actions/input';
import type { _ActionsInputInicio } from '&/actions/type';
import { Button } from '@clinica/package-react-elementos/button';
import { Input } from '@clinica/package-react-elementos/input';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@clinica/package-react-radix/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { phoneMask } from '@shared/core';
import { actions } from 'astro:actions';
import { navigate } from 'astro:transitions/client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';

function Formulario({ id }: { id: string }) {
  const form = useForm<_ActionsInputInicio>({
    resolver: zodResolver(actionsInputInicio),
    defaultValues: {
      nome: '',
      whatsapp: '',
      email: '',
      id,
    },
  });

  async function onSubmit(values: _ActionsInputInicio) {
    try {
      const { data } = await actions.inicio(values);
      navigate(`/${data.hash}`);
    } catch (error) {
      console.error('Form submission error', error);
      toast.error('Algo deu errado. Por favor, tente novamente.');
    }
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="space-y-8 max-w-lg mx-auto pb-10"
      >
        <FormField
          control={form.control}
          name="nome"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Seu nome completo</FormLabel>
              <FormControl>
                <Input
                  placeholder="Digite seu nome completo"
                  type="text"
                  {...field}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="whatsapp"
          render={({ field }) => {
            return (
              <FormItem className="flex flex-col items-start">
                <FormLabel>Seu WhatsApp</FormLabel>
                <FormControl className="w-full">
                  <Input
                    placeholder="Digite o seu WhatsApp"
                    type="tel"
                    maxLength={15}
                    minLength={14}
                    {...field}
                    value={phoneMask(field.value)}
                  />
                </FormControl>
                <FormDescription>
                  Deverá ser o seu número pessoal. Você receberá um código para
                  confirmar o registro.
                </FormDescription>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Seu email</FormLabel>
              <FormControl>
                <Input placeholder="Digite seu email" type="email" {...field} />
              </FormControl>
              <FormDescription>
                Deverá ser o seu email pessoal. Você receberá um código para
                confirmar o registro.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex items-center justify-end">
          <Button type="submit">Avançar</Button>
        </div>
      </form>
    </Form>
  );
}

export function CadastroP1() {
  const [comercar, setComercar] = useState('');

  if (comercar === '') {
    return (
      <div className="flex flex-wrap items-center justify-center gap-4">
        <Button variant="outline" onClick={() => setComercar('medico')}>
          Médica(o)
        </Button>
        <Button variant="outline" onClick={() => setComercar('psicologo')}>
          Sou Psicóloga(o)
        </Button>
        <Button variant="outline" onClick={() => setComercar('nutricionista')}>
          Sou Nutricionista
        </Button>
        <Button variant="outline" onClick={() => setComercar('fonoaudiólogo')}>
          Sou fonoaudióloga(o)
        </Button>
        <Button variant="outline" onClick={() => setComercar('enfermeiro')}>
          Sou enfermeira(o)
        </Button>
        <Button variant="outline" onClick={() => setComercar('fisio')}>
          Sou Fisioterapeuta
        </Button>
        <Button
          variant="outline"
          onClick={() => setComercar('profissional-saude')}
        >
          Sou profissional de saúde
        </Button>
        <Button variant="outline" onClick={() => setComercar('adm')}>
          Sou administrador de clínicas
        </Button>
        <Button variant="outline" onClick={() => setComercar('marketing')}>
          Sou do marketing para clínicas
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            alert('Este cadastro é apenas para profissionais de saúde.');
            window.location.href = 'https://clinica.work';
          }}
        >
          Sou paciente
        </Button>
      </div>
    );
  }

  return <Formulario id={comercar} />;
}
